import React, { useContext, useEffect, useState } from "react";
import { RefContext } from "../contexts/RefContextProvider";
import Scene from "./Scene";
import useWindowDimensions from "../hooks/useDimensions";
import useAnalyticsEventTracker from '../hooks/useAnalytics';

const Landing = () => {
  const [openScene, setOpenScene] = useState(false)
  const screenWidth = useWindowDimensions().width
  const screenHeight = useWindowDimensions().height
  const { featuresRef, roadmapRef, tokenomicsRef } = useContext(RefContext);
  const gaEventTracker = useAnalyticsEventTracker('Contact us');
  const navigate = async (ref) => {
    var headerOffset = 0;
    var elementPosition = ref.current.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
    gaEventTracker('call')
  }
  useEffect(() => {
    setOpenScene(true)
    setTimeout(() => setOpenScene(false), 100)
  }, [screenWidth])
  return (
    <>
      <div className="bg-grass h-screen overflow-hidden">
        {!openScene && <Scene screenHeight={screenHeight} screenWidth={screenWidth} />}
        <button className="flex justify-center items-center space-x-1.5 mobile:space-x-1 absolute top-[101px] mobile:top-[103px] left-1/2 -translate-x-1/2 w-[165px] mobile:w-[187px] h-[34px] text-white font-bold text-[14px] mobile:text-[16px] leading-[90%] bg-green2 rounded-[5px]"
          onClick={() => navigate(featuresRef)}
        >
          <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.75208 6.56246C5.61875 6.56246 5.49375 6.5418 5.37708 6.50046C5.26042 6.45913 5.15208 6.38813 5.05208 6.28746L0.452082 1.68746C0.268748 1.50413 0.172749 1.2748 0.164082 0.999463C0.155415 0.72413 0.251415 0.486797 0.452082 0.287463C0.635415 0.10413 0.868749 0.0124631 1.15208 0.0124631C1.43542 0.0124631 1.66875 0.10413 1.85208 0.287463L5.75208 4.16246L9.65208 0.287463C9.83541 0.10413 10.0647 0.00846302 10.3401 0.000463022C10.6154 -0.00753698 10.8527 0.0881298 11.0521 0.287463C11.2354 0.470797 11.3271 0.70413 11.3271 0.987463C11.3271 1.2708 11.2354 1.50413 11.0521 1.68746L6.45208 6.28746C6.35208 6.38746 6.24375 6.45846 6.12708 6.50046C6.01042 6.54246 5.88542 6.56313 5.75208 6.56246ZM5.75208 12.5625C5.61875 12.5625 5.49375 12.5418 5.37708 12.5005C5.26042 12.4591 5.15208 12.3881 5.05208 12.2875L0.452082 7.68746C0.268748 7.50413 0.172749 7.27513 0.164082 7.00046C0.155415 6.7258 0.251415 6.48813 0.452082 6.28746C0.635415 6.10413 0.868749 6.01246 1.15208 6.01246C1.43542 6.01246 1.66875 6.10413 1.85208 6.28746L5.75208 10.1625L9.65208 6.28746C9.83541 6.10413 10.0647 6.00813 10.3401 5.99946C10.6154 5.9908 10.8527 6.0868 11.0521 6.28746C11.2354 6.4708 11.3271 6.70413 11.3271 6.98746C11.3271 7.2708 11.2354 7.50413 11.0521 7.68746L6.45208 12.2875C6.35208 12.3875 6.24375 12.4585 6.12708 12.5005C6.01042 12.5425 5.88542 12.5631 5.75208 12.5625Z" fill="white"/>
          </svg>
          <span>Press to Scroll</span>
          <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.75208 6.56246C5.61875 6.56246 5.49375 6.5418 5.37708 6.50046C5.26042 6.45913 5.15208 6.38813 5.05208 6.28746L0.452082 1.68746C0.268748 1.50413 0.172749 1.2748 0.164082 0.999463C0.155415 0.72413 0.251415 0.486797 0.452082 0.287463C0.635415 0.10413 0.868749 0.0124631 1.15208 0.0124631C1.43542 0.0124631 1.66875 0.10413 1.85208 0.287463L5.75208 4.16246L9.65208 0.287463C9.83541 0.10413 10.0647 0.00846302 10.3401 0.000463022C10.6154 -0.00753698 10.8527 0.0881298 11.0521 0.287463C11.2354 0.470797 11.3271 0.70413 11.3271 0.987463C11.3271 1.2708 11.2354 1.50413 11.0521 1.68746L6.45208 6.28746C6.35208 6.38746 6.24375 6.45846 6.12708 6.50046C6.01042 6.54246 5.88542 6.56313 5.75208 6.56246ZM5.75208 12.5625C5.61875 12.5625 5.49375 12.5418 5.37708 12.5005C5.26042 12.4591 5.15208 12.3881 5.05208 12.2875L0.452082 7.68746C0.268748 7.50413 0.172749 7.27513 0.164082 7.00046C0.155415 6.7258 0.251415 6.48813 0.452082 6.28746C0.635415 6.10413 0.868749 6.01246 1.15208 6.01246C1.43542 6.01246 1.66875 6.10413 1.85208 6.28746L5.75208 10.1625L9.65208 6.28746C9.83541 6.10413 10.0647 6.00813 10.3401 5.99946C10.6154 5.9908 10.8527 6.0868 11.0521 6.28746C11.2354 6.4708 11.3271 6.70413 11.3271 6.98746C11.3271 7.2708 11.2354 7.50413 11.0521 7.68746L6.45208 12.2875C6.35208 12.3875 6.24375 12.4585 6.12708 12.5005C6.01042 12.5425 5.88542 12.5631 5.75208 12.5625Z" fill="white"/>
          </svg>
        </button>
      </div>
      <div className="flex flex-col items-center mx-auto max-w-[1240px] px-4 lg:px-0 py-[75px] lg:py-[79px]" ref={featuresRef}>
        <span className="font-bold text-red1 text-[30px] lg:text-[50px] leading-[150%]">LEAP INTO THE FUTURE</span>
        <span className="mt-[3px] text-grey1 text-[12px] lg:text-[16px] leading-[150%]">Discover Fronk's innovative ecosystem.</span>
        <div className="flex flex-col lg:flex-row justify-center mt-[49px] lg:mt-[34px] space-y-[56.27px] lg:space-x-[57px] lg:space-y-0">
          <div className="flex flex-col items-center text-center">
            <img className="w-[230px] h-[230px] lg:w-[251px] lg:h-[251px]" src="images/ecosystem/1.png" alt="" />
            <span className="mt-[15px] font-bold text-[25px] leading-[150%] text-white">FrogFi</span>
            <span className="max-w-[375px] text-[12px] lg:text-[16px] leading-[200%] text-grey1">Dive into our amphibious DeFi solutions, offering staking, yield farming, and lending opportunities that maximize your returns within the FRONK ecosystem.</span>
            <button className="flex justify-center items-center w-[250px] h-[50px] rounded-[10px] mt-[25px] bg-grass text-white font-medium text-[16px] leading-[150%]">COMING SOON</button>
          </div>
          <div className="flex flex-col items-center text-center">
            <img className="w-[230px] h-[230px] lg:w-[251px] lg:h-[251px]" src="images/ecosystem/2.png" alt="" />
            <span className="mt-[15px] font-bold text-[25px] leading-[150%] text-white">FrogMind AI</span>
            <span className="max-w-[375px] text-[12px] lg:text-[16px] leading-[200%] text-grey1">Experience the pinnacle of artificial intelligence in the FRONK ecosystem, where creativity meets cutting-edge technology to revolutionize the frog-themed NFT space. </span>
            <button className="flex justify-center items-center w-[250px] h-[50px] rounded-[10px] mt-[25px] bg-grass text-white font-medium text-[16px] leading-[150%]">COMING SOON</button>
          </div>
          <div className="flex flex-col items-center text-center">
            <img className="w-[230px] h-[230px] lg:w-[251px] lg:h-[251px]" src="images/ecosystem/3.png" alt="" />
            <span className="mt-[15px] font-bold text-[25px] leading-[150%] text-white">FrogCouncil</span>
            <span className="max-w-[375px] text-[12px] lg:text-[14px] leading-[200%] text-grey1">Take part in shaping the FRONK ecosystem through our decentralized FrogDAO governance.As a FRONK token holder, you'll have the power to propose and vote on platform updates, new features, and partnerships.</span>
            <button className="flex justify-center items-center w-[250px] h-[50px] rounded-[10px] mt-[25px] bg-grass text-white font-medium text-[16px] leading-[150%]">COMING SOON</button>
          </div>
        </div>
      </div>
      <div className="relative bg-grass overflow-hidden" ref={roadmapRef}>
        <div className="absolute bottom-[-90px] xl:bottom-[-167px] left-[-67px] xl:left-[-11.4583%] w-[224px] xl:w-[531px] h-[224px] xl:h-[531px] bg-ellipse1 rounded-full" />
        <div className="absolute top-[18px] xl:top-[67px] left-[21px] xl:left-[14.0277%] w-[90px] xl:w-[82px] h-[90px] xl:h-[82px] bg-ellipse1 rounded-full" />
        <div className="hidden xl:block absolute bottom-[40px] left-[31.875%] w-[82px] h-[82px] bg-ellipse1 rounded-full" />
        <div className="hidden xl:block absolute top-[-129px] left-[36.25%] w-[214px] h-[214px] bg-ellipse1 rounded-full" />
        <div className="absolute bottom-[449px] xl:bottom-[-47px] right-[-96px] xl:right-[30.0694%] w-[224px] xl:w-[214px] h-[224px] xl:h-[214px] bg-ellipse1 rounded-full" />
        <div className="absolute top-[-48px] xl:top-0 right-[-144px] xl:right-[-22.3611%] w-[350px] xl:w-[560px] h-[350px] xl:h-[560px] bg-ellipse2 rounded-full" />
        <div className="mx-auto max-w-[1440px] relative overflow-hidden">
          <div className="flex flex-col items-center py-[75px] xl:py-[87.5px] relative overflow-hidden">
            <span className="font-bold text-[30px] lg:text-[50px] leading-[150%]">ROADMAP</span>
            <span className="text-[12px] lg:text-[16px] leading-[150%]">Hop along our strategic roadmap.</span>
            <div className="flex flex-col xl:flex-row justify-center items-center space-y-[25px] xl:space-y-0 xl:space-x-[32.63px] relative z-[1] mt-[27px]">
              <div className="flex flex-col items-start py-[23px] pl-[30px] pr-[17.4px] w-[343px] xl:w-[391.58px] min-h-[250px] xl:min-h-[264px] bg-green1 rounded-[10px] border border-black">
                <div className="px-5 py-[5px] font-bold font-Inter text-[25px] leading-[120%] text-white bg-black rounded-[5px]">Phase 1</div>
                <ul className="mt-2 -ml-5 text-[14px] xl:text-[16px] leading-[175%] text-black1 roadmap-ul">
                  <li>Community Created</li>
                  <li>CoinGecko/CMC Listings</li>
                  <li>500 Holders reached</li>
                  <li>$FRONK listed on Uniswap</li>
                  <li>$FRONK liquidity locked</li>
                </ul>
              </div>
              <div className="flex flex-col items-start py-[23px] pl-[30px] pr-[17.4px] w-[343px] xl:w-[391.58px] min-h-[250px] xl:min-h-[264px] bg-green1 rounded-[10px] border border-black">
                <div className="px-5 py-[5px] font-bold font-Inter text-[25px] leading-[120%] text-white bg-black rounded-[5px]">Phase 2</div>
                <ul className="mt-2 -ml-5 text-[14px] xl:text-[16px] leading-[175%] text-black1 roadmap-ul">
                  <li>Community partnerships</li>
                  <li>$FRONK meme/shill competitions</li>
                  <li>First CEX listing</li>
                  <li>2,000 Holders reached</li>
                  <li>$FRONK Staking goes live</li>
                </ul>
              </div>
              <div className="flex flex-col items-start py-[23px] pl-[30px] pr-[17.4px] w-[343px] xl:w-[391.58px] min-h-[250px] xl:min-h-[264px] bg-green1 rounded-[10px] border border-black">
                <div className="px-5 py-[5px] font-bold font-Inter text-[25px] leading-[120%] text-white bg-black rounded-[5px]">Phase 3</div>
                <ul className="mt-2 -ml-5 text-[14px] xl:text-[16px] leading-[175%] text-black1 roadmap-ul">
                  <li>FrogDAO Governance goes live on website</li>
                  <li>FrogMind goes live on Telegram</li>
                  <li>FRONK Merch</li>
                  <li>5,000 Holders reached</li>
                  <li>Meme Takeover</li>
                  <li>5 New CEX listings</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={tokenomicsRef}>
        <div className="mx-auto max-w-[1440px] relative overflow-hidden">
          <div className="flex flex-col items-center py-[75px] lg:py-[126px] px-4">
            <span className="font-bold text-red1 text-[30px] lg:text-[50px] leading-[150%] text-center">TOKENOMICS</span>
            <span className="mt-[5px] text-grey1 text-[12px] lg:text-[16px] leading-[150%] text-center">Building a balanced and thriving frog-inspired crypto ecosystem.</span>
            <div className="flex flex-col xl:flex-row items-center xl:items-end xl:items-start mt-9 xl:mt-16 xl:space-x-[73px]">
              <div className="flex flex-col items-center">
                <span className="font-bold text-[25px] xl:text-[35px] leading-[150%] text-red1 text-center">$FRONK Token Supply<br/>10,000,000,000,000</span>
                <img className="block xl:hidden mt-[30px] w-[333px]" src="images/tokenomics/piechart-mobile.png" alt="" />
                <div className="flex flex-col items-start pl-10 pr-9 py-[41px] xl:px-10 xl:py-[55px] mt-[30px] xl:mt-[9px] w-[343px] xl:w-[586px] min-h-[264px] bg-grass rounded-[30px] border-2 border-black1">
                  <div className="flex flex-col text-[12px] xl:text-[16px] leading-[200%] text-black1">
                    <span className="font-bold text-[16px] xl:text-[20px] leading-[28px] xl:leading-[35px]">100% Tax-Free Hopping</span>
                    <span className="mt-4">A total of 95% of tokens are allocated to the liquidity pool, Liquidity Pool got burnt, and the contract was renounced. The remaining 5% of the supply is reserved exclusively for future centralized exchange listings, bridges, and liquidity pools.</span>
                  </div>
                </div>
              </div>
              <img className="hidden xl:block w-[445px]" src="images/tokenomics/piechart.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
