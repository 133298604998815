import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import useWindowDimensions from "../hooks/useDimensions";
import Footer from "./Footer";
import Topbar from "./Topbar";

const Layout = ({ children }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const screenWidth = useWindowDimensions().width;
  const screenHeight = useWindowDimensions().height;
  useMemo(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    if(screenWidth > 0 && screenHeight > 0) appHeight()
  }, [screenWidth, screenHeight])
  return (
    <div className="flex flex-col min-h-full relative">
      <Topbar openMenu={openMenu} setOpenMenu={setOpenMenu} />
      <main>{children}</main>
      <Footer />
      {openMenu &&
        <div className={`block mobile:hidden fixed z-[4] w-full h-full bg-menuback transition-all pointer-events-none	${openMenu && "pointer-events-auto"}`} />
      }
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
