import React, { useContext, useEffect } from "react";
import { RefContext } from "../contexts/RefContextProvider";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const Topbar = ({ openMenu, setOpenMenu }) => {
  const { featuresRef, roadmapRef, tokenomicsRef } = useContext(RefContext);
  const navigate = async (ref) => {
    var headerOffset = 150;
    var elementPosition = ref.current.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      // behavior: "smooth"
    });
    setOpenMenu(false);
  }
  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }, [])
  useEffect(() => {
    openMenu ? disableBodyScroll(document.body) : enableBodyScroll(document.body)
  }, [openMenu])
  return (
    <header className="absolute top-[35px] lg:top-[26px] w-full z-[6]">
      <div className="flex justify-between items-center mx-auto max-w-[1440px] px-4 mobile:pl-12 mobile:pr-[98.6px] relative">
        {openMenu ?
          <img className="h-[38.65px]" src="images/logo-sm-white.png" alt="" />
        :
          <img className="h-[38.65px]" src="images/logo-sm.png" alt="" />
        }
        <div className="hidden mobile:flex items-center space-x-[50px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold text-[16px]">
          <button onClick={() => navigate(featuresRef)}>Features</button>
          <button onClick={() => navigate(roadmapRef)}>Roadmap</button>
          <button onClick={() => navigate(tokenomicsRef)}>Tokenomics</button>
        </div>
        <div className="hidden mobile:flex items-center">
          <a href="https://t.me/fronk_coin" target="_blank" rel="noreferrer" className="flex justify-center items-center space-x-[3.79px] w-[40px] h-[40px] bg-green1 text-black1 hover:text-orange1 transition border border-black rounded-[30px]">
            <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.3051 1.63827L18.2187 16.9135C17.9856 17.9914 17.3785 18.2597 16.5158 17.7522L11.8126 14.1153L9.54358 16.406C9.29228 16.6697 9.08261 16.8897 8.59827 16.8897L8.93651 11.8637L17.6525 3.59856C18.0316 3.24437 17.5699 3.04735 17.0637 3.4023L6.28833 10.5228L1.64945 8.99874C0.640582 8.66831 0.622319 7.94001 1.85984 7.43173L20.0041 0.0958002C20.8442 -0.23462 21.5791 0.291292 21.3051 1.63827Z" fill="#140C10"/>
            </svg>
          </a>
          <a href="https://twitter.com/FronkCoin" target="_blank" rel="noreferrer" className="ml-[11px] flex justify-center items-center space-x-[3.79px] w-[40px] h-[40px] bg-green1 text-black1 hover:text-orange1 transition border border-black rounded-[30px]">
            <svg width="22" height="17" viewBox="0 0 22 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.711 2C20.941 2.35 20.111 2.58 19.251 2.69C20.131 2.16 20.811 1.32 21.131 0.31C20.301 0.81 19.381 1.16 18.411 1.36C17.621 0.5 16.511 0 15.251 0C12.901 0 10.981 1.92 10.981 4.29C10.981 4.63 11.021 4.96 11.091 5.27C7.53102 5.09 4.36102 3.38 2.25102 0.79C1.88102 1.42 1.67102 2.16 1.67102 2.94C1.67102 4.43 2.42102 5.75 3.58102 6.5C2.87102 6.5 2.21102 6.3 1.63102 6V6.03C1.63102 8.11 3.11102 9.85 5.07102 10.24C4.44174 10.4122 3.78111 10.4362 3.14102 10.31C3.41262 11.1625 3.94455 11.9084 4.66203 12.4429C5.37951 12.9775 6.24646 13.2737 7.14102 13.29C5.62465 14.4904 3.74501 15.1393 1.81102 15.13C1.47102 15.13 1.13102 15.11 0.791016 15.07C2.69102 16.29 4.95102 17 7.37102 17C15.251 17 19.581 10.46 19.581 4.79C19.581 4.6 19.581 4.42 19.571 4.23C20.411 3.63 21.131 2.87 21.711 2Z" />
            </svg>
          </a>
          <button className="ml-[12.6px] w-[150px] h-[40px] bg-green1 transition border border-black rounded-[30px] font-medium text-black1 hover:text-orange1 text-[16px] leading-[150%]">
            <div className="flex justify-center items-end space-x-[3.79px] mt-[-4px]">
              <svg width="20" height="25" viewBox="0 0 20 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.43474 11.9624C9.93792 12.9032 8.80575 13.172 8.30257 13.2392C7.54779 13.3065 7.35909 12.836 7.48489 12.2312C7.54779 12.0296 7.67359 11.828 7.79938 11.6936C7.92518 11.5591 8.17677 11.4247 8.36547 11.4247C8.55416 11.4247 8.80575 11.4247 8.93155 11.5591C9.18314 11.6263 9.30894 11.7608 9.43474 11.9624Z" />
                <path d="M12.4539 10.2823C11.8878 14.9866 19.5614 13.9785 19.4356 17.6747C20.1903 16.5995 20.5048 13.7769 18.3034 12.2984C16.2906 10.9543 13.7118 11.6935 12.4539 10.2823Z" />
                <path d="M16.9196 8.46774C16.8567 8.40054 16.7938 8.40054 16.7938 8.33333C16.7938 8.40054 16.8567 8.46774 16.9196 8.46774Z" />
                <path d="M18.6808 11.8952C18.6179 11.7608 18.555 11.6263 18.4292 11.4919C18.1776 11.0887 17.8002 10.8199 17.3599 10.7527C17.1083 10.6855 16.7938 10.6183 16.4793 10.6183C16.1648 10.6183 15.9133 10.5511 15.5988 10.5511C14.9698 10.4839 14.4037 10.4167 13.7747 10.2823C13.6489 10.2151 13.4602 10.2151 13.3344 10.1478C13.2715 10.1478 13.2086 10.0806 13.0828 10.0806C13.0199 10.0134 12.957 10.0134 12.8312 9.94624C12.5168 9.81183 12.2652 9.54301 12.0765 9.3414C11.6362 8.87097 11.2588 8.33333 10.8814 7.8629C10.504 7.32527 10.1895 6.85484 9.74923 6.38441C9.37184 5.91398 8.86865 5.51075 8.36547 5.24194C7.86228 4.97312 7.2333 4.77151 6.66721 4.7043C7.2962 4.6371 7.92518 4.7043 8.49126 4.97312C9.05735 5.24194 9.62343 5.57796 10.0637 6.04839C10.3782 6.3172 10.6298 6.65323 10.8814 6.98925C12.8312 6.58602 14.3408 6.92204 15.5359 7.66129C15.9133 7.86291 16.2906 8.13172 16.6051 8.46774C16.668 8.53495 16.7309 8.53495 16.7309 8.60215C16.9825 8.87097 17.2341 9.13979 17.4228 9.4086C18.3034 10.2823 18.6808 11.2903 18.6808 11.8952Z" />
                <path d="M6.85591 5.24194C7.35909 5.30914 7.86228 5.51075 8.17677 5.91398C8.49126 6.3172 8.61706 6.85484 8.74286 7.32527C8.80575 7.7285 8.86865 8.13172 8.99445 8.46774C9.05735 8.66935 9.18314 8.80376 9.24604 8.93817C9.30894 9.07258 9.43474 9.20699 9.49763 9.3414V9.47581C9.30894 9.67742 8.86865 9.47581 8.67996 9.3414C8.36547 9.20699 8.11387 8.93817 7.86228 8.66936C7.1704 7.86291 6.79301 6.65323 6.79301 5.57796C6.79301 5.51075 6.85591 5.37634 6.85591 5.24194Z" />
                <path d="M15.0327 14.6505C13.9634 17.8091 18.8066 19.9597 16.9825 23.1855C18.8066 22.379 19.6872 19.8925 18.9324 17.9435C18.3034 16.1962 16.2906 15.5242 15.0327 14.6505Z" />
                <path d="M8.67996 18.75C8.99445 18.5484 9.30894 18.3468 9.62343 18.1452C9.93792 18.0108 10.3153 17.8763 10.6927 17.8091C11.3846 17.6747 12.1394 17.6075 12.7054 17.3387C13.0199 17.2043 13.2715 17.0699 13.5231 16.8011C13.7747 16.5995 13.9005 16.3306 14.0263 15.9946C14.1521 15.6586 14.1521 15.3226 14.1521 14.9866C14.1521 14.5833 14.0263 14.2473 13.9005 13.9113C14.1521 14.1801 14.3408 14.5833 14.4666 14.9866C14.5924 15.3898 14.5924 15.793 14.5295 16.1962C14.4666 16.5995 14.215 17.0027 13.9634 17.3387C13.7118 17.6075 13.3344 17.8763 12.957 18.0108C12.5796 18.1452 12.2023 18.2124 11.8249 18.2796C11.4475 18.2796 11.133 18.3468 10.7556 18.3468C10.0637 18.3468 9.37184 18.4812 8.67996 18.75Z" />
                <path d="M15.473 24.328C15.3472 24.3952 15.2843 24.5296 15.1585 24.5968C15.0327 24.664 14.9069 24.7312 14.7811 24.7984C14.5295 24.9328 14.215 25 13.9005 25C13.0828 25 12.5168 24.328 12.2023 23.5887C11.9507 23.1183 11.8249 22.5806 11.5733 22.1102C11.1959 21.4382 10.504 20.9005 9.74923 20.9677C9.43474 21.0349 9.12024 21.1694 8.99445 21.4382C8.55416 22.1774 9.18314 23.2527 10.0637 23.1183C10.1266 23.1183 10.1895 23.1183 10.2524 23.0511C10.3153 23.0511 10.3782 22.9839 10.4411 22.9167C10.5669 22.7823 10.6298 22.6479 10.6927 22.5134C10.7556 22.379 10.7556 22.1774 10.7556 21.9758C10.6927 21.7742 10.6298 21.6398 10.504 21.5726C10.6927 21.6398 10.8185 21.7742 10.8814 21.9758C10.8185 22.3118 10.8185 22.5134 10.8185 22.7151C10.7556 22.9167 10.6927 23.1183 10.5669 23.2527C10.504 23.3199 10.4411 23.3871 10.3153 23.4543C10.2524 23.5215 10.1266 23.5887 10.0637 23.5887C9.87502 23.6559 9.68633 23.6559 9.49763 23.6559C9.24604 23.5887 8.93155 23.5215 8.74286 23.3199C8.49126 23.1183 8.30257 22.7823 8.05097 22.5806C7.79938 22.2446 7.48489 22.043 7.1075 21.8414C6.85591 21.707 6.60432 21.6398 6.35272 21.5726C6.22693 21.5726 6.10113 21.5054 5.97533 21.5054C5.91243 21.5054 5.59794 21.4382 5.59794 21.4382C6.03823 21.035 6.47852 20.6989 6.91881 20.3629C7.42199 20.0269 7.92518 19.8253 8.49126 19.6237C9.05735 19.422 9.62343 19.422 10.2524 19.4893C10.5669 19.5565 10.8185 19.6237 11.133 19.7581C11.4475 19.8925 11.6991 20.0941 11.9507 20.2957C12.2023 20.5645 12.391 20.8333 12.5168 21.1022C12.6425 21.371 12.7054 21.707 12.7683 22.043C12.957 22.9839 12.8941 24.4624 14.0263 24.664C14.0892 24.664 14.1521 24.664 14.215 24.664H14.4037C14.5295 24.664 14.6553 24.664 14.7811 24.5968C14.9698 24.5968 15.2214 24.4624 15.473 24.328Z" />
                <path d="M7.73648 10.0134C7.67359 10.2151 7.61069 10.3495 7.54779 10.4839C7.35909 10.7527 7.1075 10.9543 6.85591 11.0887C6.60432 11.2231 6.35272 11.2903 6.03823 11.2903C5.97533 11.2903 5.91244 11.2903 5.84954 11.2903C5.66084 11.2903 5.53504 11.3575 5.34635 11.4919C5.22055 11.6263 5.09476 11.7608 5.09476 11.9624C5.09476 12.0296 5.03186 12.164 5.03186 12.2312C5.03186 12.5 4.96896 12.7016 4.96896 13.0376C4.90606 13.5753 4.78027 14.0457 4.59157 14.5161C4.33998 15.121 4.02549 15.6586 4.08839 16.3306C4.15128 16.8011 4.33998 17.1371 4.65447 17.4059C5.15766 18.0108 6.35272 18.2796 6.10113 19.6909C5.91243 20.5645 4.59157 21.4382 2.76752 21.7742C2.95622 21.7742 2.51593 20.9677 2.51593 20.9005C2.32723 20.5645 2.07564 20.2285 1.94985 19.8925C1.63535 19.2204 1.50956 18.3468 1.63535 17.6075C1.76115 16.8011 2.32724 16.1962 2.76752 15.5242C3.33361 14.7849 3.89969 13.8441 4.02549 12.836C4.02549 12.6344 4.08839 12.2984 4.15128 12.0296C4.21418 11.6936 4.27708 11.4247 4.40288 11.0887C4.46577 10.8871 4.59157 10.7527 4.78027 10.5511C4.84316 10.4839 4.90606 10.3495 4.90606 10.2823C4.90606 10.1479 4.90606 10.0806 4.84316 9.94624L1.88695 4.23387L6.16403 9.87903C6.22693 9.94624 6.28982 10.0134 6.35272 10.0134C6.41562 10.0806 6.47852 10.0806 6.60431 10.0806C6.66721 10.0806 6.73011 10.0806 6.85591 10.0134C6.91881 9.94624 6.9817 9.94624 7.0446 9.87903C7.1075 9.81183 7.1075 9.74462 7.1075 9.61022C7.1075 9.54301 7.1075 9.4086 7.0446 9.3414C6.79301 8.93817 6.47852 8.60215 6.16403 8.19893L2.95622 3.96506L0 0L3.20781 3.69624L6.60431 7.7285C6.9817 8.19893 7.35909 8.60215 7.73648 9.13978L7.79938 9.20699V9.3414C7.79938 9.61022 7.79938 9.81183 7.73648 10.0134Z" />
                <path d="M8.74286 23.2527C8.55416 23.0511 8.36547 22.8495 8.17677 22.6479C8.36547 22.9167 8.49126 23.1183 8.74286 23.2527Z" />
              </svg>
              <span className="max-h-[21px]">Buy Now</span>
            </div>
          </button>
        </div>
        <button className="flex mobile:hidden justify-center items-center space-x-[3.79px] w-[40px] h-[40px] bg-green1 text-black1 hover:text-orange1 transition border-2 border-black1 rounded-[30px]"
          onClick={() => setOpenMenu(!openMenu)}
        >
          {openMenu ?
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.5 10.3099L2.1654 16.6445C1.92839 16.8815 1.62674 17 1.26046 17C0.89417 17 0.592522 16.8815 0.355513 16.6445C0.118504 16.4075 0 16.1058 0 15.7395C0 15.3733 0.118504 15.0716 0.355513 14.8346L6.69011 8.5L0.355513 2.1654C0.118504 1.92839 0 1.62674 0 1.26046C0 0.89417 0.118504 0.592522 0.355513 0.355513C0.592522 0.118504 0.89417 0 1.26046 0C1.62674 0 1.92839 0.118504 2.1654 0.355513L8.5 6.69011L14.8346 0.355513C15.0716 0.118504 15.3733 0 15.7395 0C16.1058 0 16.4075 0.118504 16.6445 0.355513C16.8815 0.592522 17 0.89417 17 1.26046C17 1.62674 16.8815 1.92839 16.6445 2.1654L10.3099 8.5L16.6445 14.8346C16.8815 15.0716 17 15.3733 17 15.7395C17 16.1058 16.8815 16.4075 16.6445 16.6445C16.4075 16.8815 16.1058 17 15.7395 17C15.3733 17 15.0716 16.8815 14.8346 16.6445L8.5 10.3099Z" fill="black"/>
            </svg>
          :
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 18C9.10457 18 10 17.1046 10 16C10 14.8954 9.10457 14 8 14C6.89543 14 6 14.8954 6 16C6 17.1046 6.89543 18 8 18Z" fill="black"/>
              <path d="M16 18C17.1046 18 18 17.1046 18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18Z" fill="black"/>
              <path d="M24 18C25.1046 18 26 17.1046 26 16C26 14.8954 25.1046 14 24 14C22.8954 14 22 14.8954 22 16C22 17.1046 22.8954 18 24 18Z" fill="black"/>
            </svg>
          }
        </button>

        {openMenu &&
          <div className="flex mobile:hidden flex-col items-center mt-[62px] absolute top-full left-0 w-screen">
            <div className="flex flex-col items-center space-y-[50px] font-bold text-[16px] leading-[90%] text-white">
              <button className="hover:text-green1 transition-all" onClick={() => navigate(featuresRef)}>Features</button>
              <button className="hover:text-green1 transition-all" onClick={() => navigate(roadmapRef)}>Roadmap</button>
              <button className="hover:text-green1 transition-all" onClick={() => navigate(tokenomicsRef)}>Tokenomics</button>
            </div>
            <div className="flex flex-col items-center space-y-5 mt-[50px]">
              <a href="https://t.me/fronk_coin" target="_blank" rel="noreferrer" className="flex justify-center items-center w-[163px] h-[40px] space-x-[5px] bg-green1 border border-black rounded-[30px]">
                <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.3944 1.63827L17.3079 16.9135C17.0749 17.9914 16.4678 18.2597 15.6051 17.7522L10.9019 14.1153L8.63287 16.406C8.38157 16.6697 8.17191 16.8897 7.68756 16.8897L8.0258 11.8637L16.7418 3.59856C17.1209 3.24437 16.6592 3.04735 16.153 3.4023L5.37762 10.5228L0.738742 8.99874C-0.270124 8.66831 -0.288387 7.94001 0.949135 7.43173L19.0934 0.0958002C19.9335 -0.23462 20.6684 0.291292 20.3944 1.63827Z" fill="#140C10"/>
                </svg>
                <span className="font-medium text-[16px] leading-[90%]">Join Telegram</span>
              </a>
              <a href="https://twitter.com/FronkCoin" target="_blank" rel="noreferrer" className="flex justify-center items-center w-[163px] h-[40px] space-x-[5px] bg-green1 border border-black rounded-[30px]">
                <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.92 2C20.15 2.35 19.32 2.58 18.46 2.69C19.34 2.16 20.02 1.32 20.34 0.31C19.51 0.81 18.59 1.16 17.62 1.36C16.83 0.5 15.72 0 14.46 0C12.11 0 10.19 1.92 10.19 4.29C10.19 4.63 10.23 4.96 10.3 5.27C6.74 5.09 3.57 3.38 1.46 0.79C1.09 1.42 0.88 2.16 0.88 2.94C0.88 4.43 1.63 5.75 2.79 6.5C2.08 6.5 1.42 6.3 0.84 6V6.03C0.84 8.11 2.32 9.85 4.28 10.24C3.65073 10.4122 2.9901 10.4362 2.35 10.31C2.62161 11.1625 3.15354 11.9084 3.87102 12.4429C4.5885 12.9775 5.45545 13.2737 6.35 13.29C4.83363 14.4904 2.954 15.1393 1.02 15.13C0.68 15.13 0.34 15.11 0 15.07C1.9 16.29 4.16 17 6.58 17C14.46 17 18.79 10.46 18.79 4.79C18.79 4.6 18.79 4.42 18.78 4.23C19.62 3.63 20.34 2.87 20.92 2Z" fill="#140C10"/>
                </svg>
                <span className="font-medium text-[16px] leading-[90%]">Follow Twitter</span>
              </a>
              <a href="https://coinmarketcap.com" target="_blank" rel="noreferrer" className="flex justify-center items-center w-[163px] h-[40px] space-x-[5px] bg-green1 border border-black rounded-[30px]">
                <svg className="mt-[-6px]" width="20" height="25" viewBox="0 0 20 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.43474 11.9624C9.93792 12.9032 8.80575 13.172 8.30257 13.2392C7.54779 13.3065 7.35909 12.836 7.48489 12.2312C7.54779 12.0296 7.67359 11.828 7.79938 11.6936C7.92518 11.5591 8.17677 11.4247 8.36547 11.4247C8.55416 11.4247 8.80575 11.4247 8.93155 11.5591C9.18314 11.6263 9.30894 11.7608 9.43474 11.9624Z" />
                  <path d="M12.4539 10.2823C11.8878 14.9866 19.5614 13.9785 19.4356 17.6747C20.1903 16.5995 20.5048 13.7769 18.3034 12.2984C16.2906 10.9543 13.7118 11.6935 12.4539 10.2823Z" />
                  <path d="M16.9196 8.46774C16.8567 8.40054 16.7938 8.40054 16.7938 8.33333C16.7938 8.40054 16.8567 8.46774 16.9196 8.46774Z" />
                  <path d="M18.6808 11.8952C18.6179 11.7608 18.555 11.6263 18.4292 11.4919C18.1776 11.0887 17.8002 10.8199 17.3599 10.7527C17.1083 10.6855 16.7938 10.6183 16.4793 10.6183C16.1648 10.6183 15.9133 10.5511 15.5988 10.5511C14.9698 10.4839 14.4037 10.4167 13.7747 10.2823C13.6489 10.2151 13.4602 10.2151 13.3344 10.1478C13.2715 10.1478 13.2086 10.0806 13.0828 10.0806C13.0199 10.0134 12.957 10.0134 12.8312 9.94624C12.5168 9.81183 12.2652 9.54301 12.0765 9.3414C11.6362 8.87097 11.2588 8.33333 10.8814 7.8629C10.504 7.32527 10.1895 6.85484 9.74923 6.38441C9.37184 5.91398 8.86865 5.51075 8.36547 5.24194C7.86228 4.97312 7.2333 4.77151 6.66721 4.7043C7.2962 4.6371 7.92518 4.7043 8.49126 4.97312C9.05735 5.24194 9.62343 5.57796 10.0637 6.04839C10.3782 6.3172 10.6298 6.65323 10.8814 6.98925C12.8312 6.58602 14.3408 6.92204 15.5359 7.66129C15.9133 7.86291 16.2906 8.13172 16.6051 8.46774C16.668 8.53495 16.7309 8.53495 16.7309 8.60215C16.9825 8.87097 17.2341 9.13979 17.4228 9.4086C18.3034 10.2823 18.6808 11.2903 18.6808 11.8952Z" />
                  <path d="M6.85591 5.24194C7.35909 5.30914 7.86228 5.51075 8.17677 5.91398C8.49126 6.3172 8.61706 6.85484 8.74286 7.32527C8.80575 7.7285 8.86865 8.13172 8.99445 8.46774C9.05735 8.66935 9.18314 8.80376 9.24604 8.93817C9.30894 9.07258 9.43474 9.20699 9.49763 9.3414V9.47581C9.30894 9.67742 8.86865 9.47581 8.67996 9.3414C8.36547 9.20699 8.11387 8.93817 7.86228 8.66936C7.1704 7.86291 6.79301 6.65323 6.79301 5.57796C6.79301 5.51075 6.85591 5.37634 6.85591 5.24194Z" />
                  <path d="M15.0327 14.6505C13.9634 17.8091 18.8066 19.9597 16.9825 23.1855C18.8066 22.379 19.6872 19.8925 18.9324 17.9435C18.3034 16.1962 16.2906 15.5242 15.0327 14.6505Z" />
                  <path d="M8.67996 18.75C8.99445 18.5484 9.30894 18.3468 9.62343 18.1452C9.93792 18.0108 10.3153 17.8763 10.6927 17.8091C11.3846 17.6747 12.1394 17.6075 12.7054 17.3387C13.0199 17.2043 13.2715 17.0699 13.5231 16.8011C13.7747 16.5995 13.9005 16.3306 14.0263 15.9946C14.1521 15.6586 14.1521 15.3226 14.1521 14.9866C14.1521 14.5833 14.0263 14.2473 13.9005 13.9113C14.1521 14.1801 14.3408 14.5833 14.4666 14.9866C14.5924 15.3898 14.5924 15.793 14.5295 16.1962C14.4666 16.5995 14.215 17.0027 13.9634 17.3387C13.7118 17.6075 13.3344 17.8763 12.957 18.0108C12.5796 18.1452 12.2023 18.2124 11.8249 18.2796C11.4475 18.2796 11.133 18.3468 10.7556 18.3468C10.0637 18.3468 9.37184 18.4812 8.67996 18.75Z" />
                  <path d="M15.473 24.328C15.3472 24.3952 15.2843 24.5296 15.1585 24.5968C15.0327 24.664 14.9069 24.7312 14.7811 24.7984C14.5295 24.9328 14.215 25 13.9005 25C13.0828 25 12.5168 24.328 12.2023 23.5887C11.9507 23.1183 11.8249 22.5806 11.5733 22.1102C11.1959 21.4382 10.504 20.9005 9.74923 20.9677C9.43474 21.0349 9.12024 21.1694 8.99445 21.4382C8.55416 22.1774 9.18314 23.2527 10.0637 23.1183C10.1266 23.1183 10.1895 23.1183 10.2524 23.0511C10.3153 23.0511 10.3782 22.9839 10.4411 22.9167C10.5669 22.7823 10.6298 22.6479 10.6927 22.5134C10.7556 22.379 10.7556 22.1774 10.7556 21.9758C10.6927 21.7742 10.6298 21.6398 10.504 21.5726C10.6927 21.6398 10.8185 21.7742 10.8814 21.9758C10.8185 22.3118 10.8185 22.5134 10.8185 22.7151C10.7556 22.9167 10.6927 23.1183 10.5669 23.2527C10.504 23.3199 10.4411 23.3871 10.3153 23.4543C10.2524 23.5215 10.1266 23.5887 10.0637 23.5887C9.87502 23.6559 9.68633 23.6559 9.49763 23.6559C9.24604 23.5887 8.93155 23.5215 8.74286 23.3199C8.49126 23.1183 8.30257 22.7823 8.05097 22.5806C7.79938 22.2446 7.48489 22.043 7.1075 21.8414C6.85591 21.707 6.60432 21.6398 6.35272 21.5726C6.22693 21.5726 6.10113 21.5054 5.97533 21.5054C5.91243 21.5054 5.59794 21.4382 5.59794 21.4382C6.03823 21.035 6.47852 20.6989 6.91881 20.3629C7.42199 20.0269 7.92518 19.8253 8.49126 19.6237C9.05735 19.422 9.62343 19.422 10.2524 19.4893C10.5669 19.5565 10.8185 19.6237 11.133 19.7581C11.4475 19.8925 11.6991 20.0941 11.9507 20.2957C12.2023 20.5645 12.391 20.8333 12.5168 21.1022C12.6425 21.371 12.7054 21.707 12.7683 22.043C12.957 22.9839 12.8941 24.4624 14.0263 24.664C14.0892 24.664 14.1521 24.664 14.215 24.664H14.4037C14.5295 24.664 14.6553 24.664 14.7811 24.5968C14.9698 24.5968 15.2214 24.4624 15.473 24.328Z" />
                  <path d="M7.73648 10.0134C7.67359 10.2151 7.61069 10.3495 7.54779 10.4839C7.35909 10.7527 7.1075 10.9543 6.85591 11.0887C6.60432 11.2231 6.35272 11.2903 6.03823 11.2903C5.97533 11.2903 5.91244 11.2903 5.84954 11.2903C5.66084 11.2903 5.53504 11.3575 5.34635 11.4919C5.22055 11.6263 5.09476 11.7608 5.09476 11.9624C5.09476 12.0296 5.03186 12.164 5.03186 12.2312C5.03186 12.5 4.96896 12.7016 4.96896 13.0376C4.90606 13.5753 4.78027 14.0457 4.59157 14.5161C4.33998 15.121 4.02549 15.6586 4.08839 16.3306C4.15128 16.8011 4.33998 17.1371 4.65447 17.4059C5.15766 18.0108 6.35272 18.2796 6.10113 19.6909C5.91243 20.5645 4.59157 21.4382 2.76752 21.7742C2.95622 21.7742 2.51593 20.9677 2.51593 20.9005C2.32723 20.5645 2.07564 20.2285 1.94985 19.8925C1.63535 19.2204 1.50956 18.3468 1.63535 17.6075C1.76115 16.8011 2.32724 16.1962 2.76752 15.5242C3.33361 14.7849 3.89969 13.8441 4.02549 12.836C4.02549 12.6344 4.08839 12.2984 4.15128 12.0296C4.21418 11.6936 4.27708 11.4247 4.40288 11.0887C4.46577 10.8871 4.59157 10.7527 4.78027 10.5511C4.84316 10.4839 4.90606 10.3495 4.90606 10.2823C4.90606 10.1479 4.90606 10.0806 4.84316 9.94624L1.88695 4.23387L6.16403 9.87903C6.22693 9.94624 6.28982 10.0134 6.35272 10.0134C6.41562 10.0806 6.47852 10.0806 6.60431 10.0806C6.66721 10.0806 6.73011 10.0806 6.85591 10.0134C6.91881 9.94624 6.9817 9.94624 7.0446 9.87903C7.1075 9.81183 7.1075 9.74462 7.1075 9.61022C7.1075 9.54301 7.1075 9.4086 7.0446 9.3414C6.79301 8.93817 6.47852 8.60215 6.16403 8.19893L2.95622 3.96506L0 0L3.20781 3.69624L6.60431 7.7285C6.9817 8.19893 7.35909 8.60215 7.73648 9.13978L7.79938 9.20699V9.3414C7.79938 9.61022 7.79938 9.81183 7.73648 10.0134Z" />
                  <path d="M8.74286 23.2527C8.55416 23.0511 8.36547 22.8495 8.17677 22.6479C8.36547 22.9167 8.49126 23.1183 8.74286 23.2527Z" />
                </svg>
                <span className="font-medium text-[16px] leading-[90%]">Buy Now</span>
              </a>
            </div>
          </div>
        }
      </div>
    </header>
  );
};

export default Topbar;
