import React from "react";
import Matter from "matter-js";

class Scene extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var Engine = Matter.Engine,
      Render = Matter.Render,
      World = Matter.World,
      Bodies = Matter.Bodies,
      Mouse = Matter.Mouse,
      MouseConstraint = Matter.MouseConstraint;

    var engine = Engine.create({
      // positionIterations: 20
      render: {
        options: {
          pixelRatio: 'auto'
        }
      }
    });
    var height = window.innerHeight
    var render = Render.create({
      element: this.refs.scene,
      engine: engine,
      options: {
        width: window.innerWidth,
        height,
        wireframes: false,
        background: "transparent",
        hasBounds: true,
        pixelRatio: window.devicePixelRatio
      }
    });

    let balls = []
    console.log(height)
    if(window.innerWidth >= 800) {
      balls.push(Bodies.circle(window.innerWidth * (140 / 1440), 176, 43.5, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/1.webp',
            xScale: 87/402,
            yScale: 87/416
          }
        }
      }));
      balls.push(Bodies.circle(window.innerWidth * (118 / 1440), 473.42, 80, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/1.webp',
            xScale: 160/402,
            yScale: 160/416
          }
        }
      }));
      balls.push(Bodies.circle(window.innerWidth * (282 / 1440), 106, 63.5, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/1.webp',
            xScale: 127/402,
            yScale: 127/416
          }
        }
      }));
      balls.push(Bodies.circle(window.innerWidth * (509 / 1440), 349, 70.5, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/1.webp',
            xScale: 141/402,
            yScale: 141/416
          }
        }
      }));
      balls.push(Bodies.circle(window.innerWidth * (660 / 1440), 110, 42.5, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/1.webp',
            xScale: 85/402,
            yScale: 85/416
          }
        }
      }));
      balls.push(Bodies.circle(window.innerWidth * (707 / 1440), 443, 100.5, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/1.webp',
            xScale: 201/402,
            yScale: 201/416
          }
        }
      }));
      balls.push(Bodies.circle(window.innerWidth * (1155 / 1440), 121, 74.5, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/1.webp',
            xScale: 149/402,
            yScale: 149/416
          }
        }
      }));
      balls.push(Bodies.circle(window.innerWidth * (1155 / 1440), 536, 74.5, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/1.webp',
            xScale: 149/402,
            yScale: 149/416
          }
        }
      }));

      balls.push(Bodies.circle(window.innerWidth * (217.38 / 1440), 341.19, 45.5, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/2.webp',
            xScale: 91/301,
            yScale: 91/277
          }
        }
      }));
      balls.push(Bodies.circle(window.innerWidth * (418 / 1440), 604, 45.5, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/2.webp',
            xScale: 91/301,
            yScale: 91/277
          }
        }
      }));
      balls.push(Bodies.circle(window.innerWidth * (1005 / 1440), 226, 68.895, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/2.webp',
            xScale: 137.79/301,
            yScale: 137.79/277
          }
        }
      }));
      balls.push(Bodies.circle(window.innerWidth * (1098 / 1440), 388, 45.5, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/2.webp',
            xScale: 91/301,
            yScale: 91/277
          }
        }
      }));
      balls.push(Bodies.circle(window.innerWidth * (300 / 1440), 500, 45.5, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/2.webp',
            xScale: 91/301,
            yScale: 91/277
          }
        }
      }));
      balls.push(Bodies.circle(window.innerWidth * (500 / 1440), 700, 45.5, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/2.webp',
            xScale: 91/301,
            yScale: 91/277
          }
        }
      }));
      balls.push(Bodies.circle(window.innerWidth * (700 / 1440), 800, 45.5, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/2.webp',
            xScale: 91/301,
            yScale: 91/277
          }
        }
      }));
      
      balls.push(Bodies.rectangle(window.innerWidth * (88 / 1440), 283, 92, 141, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/3/1.webp',
            xScale: 92/184,
            yScale: 141/281
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (328 / 1440), 419, 111, 141, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/3/2.webp',
            xScale: 111/222,
            yScale: 141/281
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (685 / 1440), 208, 181, 180, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/3/3.webp',
            xScale: 181/361,
            yScale: 180/360
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (967 / 1440), 476, 126, 141, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/3/4.webp',
            xScale: 126/252,
            yScale: 141/281
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (1205 / 1440), 321, 124, 141, { 
        restitution: 0.5,
        mass: 200,
        render: {
          sprite: {
            texture: 'images/hero/3/5.webp',
            xScale: 124/248,
            yScale: 141/281
          }
        }
      }));

      balls.push(Bodies.rectangle(window.innerWidth * (337 / 1440), 274, 145, 124, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 145/310,
            yScale: 124/264
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (507 / 1440), 189, 110, 94, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 110/310,
            yScale: 94/264
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (907 / 1440), 156, 119, 102, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 119/310,
            yScale: 102/264
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (918 / 1440), 341, 155, 132, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 155/310,
            yScale: 132/264
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (337 / 1440), 274, 145, 124, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 145/310,
            yScale: 124/264
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (507 / 1440), 189, 110, 94, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 110/310,
            yScale: 94/264
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (907 / 1440), 156, 119, 102, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 119/310,
            yScale: 102/264
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (600 / 1440), 500, 110, 94, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 110/310,
            yScale: 94/264
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (700 / 1440), 200, 119, 102, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 119/310,
            yScale: 102/264
          }
        }
      }));
      
      for(let i=0;i<Math.round((window.innerWidth - 1000) / 20);i++) {
        balls.push(Bodies.rectangle(i*10, 300 * (i%2 ? 1:-1), 155, 132, { 
          restitution: 0.5,
          render: {
            sprite: {
              texture: 'images/hero/4.webp',
              xScale: 155/310,
              yScale: 132/264
            }
          }
        }));
      }
    } else {
      balls.push(Bodies.circle(window.innerWidth * (52.5 / 375), 291, 35.5 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/1.webp',
            xScale: 71/402 * 1.15,
            yScale: 71/416 * 1.15
          },
        },
      }));
      balls.push(Bodies.circle(window.innerWidth * (120 / 375), 152.03, 43.5 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/1.webp',
            xScale: 87/402 * 1.15,
            yScale: 87/416 * 1.15
          },
        },
      }));
      balls.push(Bodies.circle(window.innerWidth * (133 / 375), 446, 35.5 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/1.webp',
            xScale: 71/402 * 1.15,
            yScale: 71/416 * 1.15
          },
        },
      }));
      balls.push(Bodies.circle(window.innerWidth * (187 / 375), 244, 31.5 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/1.webp',
            xScale: 63/402 * 1.15,
            yScale: 63/416 * 1.15
          },
        },
      }));
      balls.push(Bodies.circle(window.innerWidth * (268 / 375), 394, 31.5 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/1.webp',
            xScale: 63/402 * 1.15,
            yScale: 63/416 * 1.15
          },
        },
      }));
      balls.push(Bodies.circle(window.innerWidth * (304 / 375), 577, 26.5 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/1.webp',
            xScale: 53/402 * 1.15,
            yScale: 53/416 * 1.15
          },
        },
      }));

      balls.push(Bodies.circle(window.innerWidth * (57.14 / 375), 376.41, 20 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/2.webp',
            xScale: 40/301 * 1.15,
            yScale: 40/277 * 1.15
          }
        }
      }));
      balls.push(Bodies.circle(window.innerWidth * (134.27 / 375), 580, 32.71 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/2.webp',
            xScale: 63.42/301 * 1.15,
            yScale: 63.42/277 * 1.15
          }
        }
      }));
      balls.push(Bodies.circle(window.innerWidth * (207 / 375), 181, 25.685 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/2.webp',
            xScale: 51.37/301 * 1.15,
            yScale: 51.37/277 * 1.15
          }
        }
      }));
      balls.push(Bodies.circle(window.innerWidth * (290 / 375), 325, 32.14 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/2.webp',
            xScale: 63.42/301 * 1.15,
            yScale: 63.42/277 * 1.15
          }
        }
      }));
      
      balls.push(Bodies.rectangle(window.innerWidth * (50 / 375), 164, 62 * 1.15, 100 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/3/1.webp',
            xScale: 62/184 * 1.15,
            yScale: 100/281 * 1.15
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (300 / 375), 225, 73 * 1.15, 100 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/3/2.webp',
            xScale: 73/222 * 1.15,
            yScale: 100/281 * 1.15
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (109 / 375), 300, 110 * 1.15, 126 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/3/3.webp',
            xScale: 110/361 * 1.15,
            yScale: 110/360 * 1.15
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (60 / 375), 476, 84 * 1.15, 100 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/3/4.webp',
            xScale: 84/252 * 1.15,
            yScale: 100/281 * 1.15
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (238 / 375), 321, 78 * 1.15, 100 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/3/5.webp',
            xScale: 78/248 * 1.15,
            yScale: 100/281 * 1.15
          }
        }
      }));

      balls.push(Bodies.rectangle(window.innerWidth * (35 / 375), 200, 100 * 1.15, 83.75 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 100/310 * 1.15,
            yScale: 83.75/264 * 1.15
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (35 / 375), 180, 98 * 1.15, 83.75 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 98/310 * 1.15,
            yScale: 83.75/264 * 1.15
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (35 / 375), 300, 98 * 1.15, 83.75 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 98/310 * 1.15,
            yScale: 83.75/264 * 1.15
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (35 / 375), 540, 98 * 1.15, 83.75 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 98/310 * 1.15,
            yScale: 83.75/264 * 1.15
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (88 / 375), 232, 84 * 1.15, 71.78 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 84/310 * 1.15,
            yScale: 71.78/264 * 1.15
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (170 / 375), 531, 66 * 1.15, 56.4 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 84/310 * 1.15,
            yScale: 71.78/264 * 1.15
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (204 / 375), 427, 54 * 1.15, 46.15 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 54/310 * 1.15,
            yScale: 46.15/264 * 1.15
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (226 / 375), 337, 64 * 1.15, 54 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 64/310 * 1.15,
            yScale: 54/264 * 1.15
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (255 / 375), 148, 82 * 1.15, 70.07 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 82/310 * 1.15,
            yScale: 70.07/264 * 1.15
          }
        }
      }));
      balls.push(Bodies.rectangle(window.innerWidth * (35 / 375), 180, 98 * 1.15, 83.75 * 1.15, { 
        restitution: 0.5,
        render: {
          sprite: {
            texture: 'images/hero/4.webp',
            xScale: 98/310 * 1.15,
            yScale: 83.75/264 * 1.15
          }
        }
      }));
      for(let i=0;i<Math.round((window.innerWidth - 375) / 50);i++) {
        balls.push(Bodies.rectangle(window.innerWidth * (35 / 375) + i * 10, 200 * (i%2 ? 1:-1), 98 * 1.15, 83.75 * 1.15, { 
          restitution: 0.5,
          render: {
            sprite: {
              texture: 'images/hero/4.webp',
              xScale: 98/310 * 1.15,
              yScale: 83.75/264 * 1.15
            }
          }
        }));
      }
    }
    World.add(engine.world, [
      // walls
      Bodies.rectangle(window.innerWidth/2, -5, window.innerWidth, 10, { isStatic: true }),
      Bodies.rectangle(window.innerWidth/2, height+2, window.innerWidth, 10, { isStatic: true, render: {
        strokeStyle: 'transparent',
        fillStyle: 'transparent'
      } }),
      Bodies.rectangle(-5, height/2, 10, height, { isStatic: true }),
      Bodies.rectangle(window.innerWidth+5, height/2, 10, height, { isStatic: true }),
    ]);

    World.add(engine.world, balls);

    // add mouse control
    var mouse = Mouse.create(render.canvas),
      mouseConstraint = MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.2,
          render: {
            visible: false
          }
        }
      });

    mouseConstraint.mouse.element.removeEventListener("mousewheel", mouseConstraint.mouse.mousewheel);
    mouseConstraint.mouse.element.removeEventListener("DOMMouseScroll", mouseConstraint.mouse.mousewheel);
    World.add(engine.world, mouseConstraint);

    Engine.run(engine);

    Render.run(render);
  }

  render() {
    return <div ref="scene" />;
  }
}
export default Scene;